import iconCheck from "../../images/icons/styled/first/CheckInStar.svg";
import calendar from "../../images/icons/styled/first/Calendar.svg";
import Hat from "../../images/icons/styled/first/Hat.svg";
import ShieldHR from "../../images/icons/styled/first/ShieldHR.svg";

export default {
    "seo": {
        "title": "Bank 131 | Jobs",
        "description": "Working at Bank 131. Bank 131 is a fintech company with a banking license. We create targeted financial products and payment solutions for large enterprises. Among our clients are marketplaces, digital service providers, and international companies that want to work in the Russian market."
    },
    "hrBlock": {
        "title": "Work with Bank 131",
        "description": "We create products that exceed expectations",
        "subdescription": "We are growing apace, and create custom solutions for inspiring companies and projects to help them be more efficient."
    },

    "VacanciesTitle": "View job openings",
    "VacanciesLink": "https://hh.ru/employer/5725304",

    "bottomCaptionTitle": "Drop us a line at ",
    "bottomCaptionMail": "job@131.ru",

    "HRCardsBlock": {
        "cardsTitle": "How we are working",
        "cards": [
            {
                "title": "It’s a marathon, not a sprint",
                "text": "We’re results-focused, but we enjoy the process, too",
                "icon": iconCheck,
                "description": "",
                "theme": "cardSecond"
            },
            {
                "title": "Flexibility",
                "text": "We’re ready for change and welcome new approaches and visions",
                "icon": iconCheck,
                "description": "",
                "theme": "cardSecond"
            },
            {
                "title": "Fast and straightforward",
                "text": "We are still a start-up and remain committed to open discussion, confident decision-making, and a proactive approach",
                "icon": iconCheck,
                "description": "",
                "theme": "cardSecond"
            }
        ]
    },

    "HRCardsBlockSecond": {
            "cardsTitle": "Our advantages",
            "cards": [
                {
                    "title": "Flexible hours",
                    "text": "so we stay productive",
                    "icon": calendar,
                    "description": "",
                    "theme": "cardFirst"
                },
                {
                    "title": "Medical coverage",
                    "text": "to keep us healthy and full of energy",
                    "icon": ShieldHR,
                    "description": "",
                    "theme": "cardFirst"
                },
                {
                    "title": "Internal get-togethers",
                    "text": "to develop skills and broaden horizons",
                    "icon": Hat,
                    "description": "",
                    "theme": "cardFirst"
                }
            ]
        }
}