import React from 'react';
import PropTypes from 'prop-types';
import Icon from "./icons/Icon";
import { Link } from 'gatsby';

import '../styles/buttons/textButton.scss';

const TextButton = ({children, iconId, iconPosition = 'after', size = 'normal', type = 'link', to, href, className, color = 'blue', styleTheme }) => {
    const content = React.useMemo(() => {
        return (
            <React.Fragment>
                {iconId && iconPosition === 'before' && (
                    <Icon idIcon={iconId} className="TextButton-Icon TextButton-Icon_position_before" />
                )}
                {children}
                {iconId && iconPosition === 'after' && (
                    <Icon idIcon={iconId} className="TextButton-Icon TextButton-Icon_position_after" />
                )}
            </React.Fragment>
        )
    }, [children, iconId, iconPosition]);

    const classes = React.useMemo(() => {
        let cls = 'TextButton';
        cls += className ? ' ' + className : '';
        cls += size ? ' TextButton_size_' + size : '';
        cls += color ? ' TextButton_color_' + color : '';
        cls += styleTheme ? ' TextButton_theme_' + styleTheme : '';

        return cls;
    }, [className, size, color, styleTheme]);

    return (
        <React.Fragment>
            {to && (
                <Link to={to} className={classes}>{content}</Link>
            )}
            {type === 'span' && (
                <span className={classes}>{content}</span>
            )}
            {href && (
                <a className={classes} href={href} target="_blank" rel="noreferrer">{content}</a>
            )}
        </React.Fragment>
    )
};

TextButton.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    iconId: PropTypes.string,
    iconPosition: PropTypes.oneOf(['before', 'after']),
    size: PropTypes.oneOf(['normal', 'small']),
    className: PropTypes.string,
    type: PropTypes.oneOf(['span']),
    to: PropTypes.string,
    href: PropTypes.string,
    color: PropTypes.oneOf(['blue', 'white']),
    styleTheme: PropTypes.string
};

export default TextButton;
