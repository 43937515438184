import React from 'react';

import '../../styles/components/icon.scss'

const Icon = ({ className, idIcon }) => {
    const useTag = React.useMemo(() => {
        return '<use xlink:href="#' + idIcon + '" />';
    }, [idIcon]);
    const classes = React.useMemo(() => {
        let cls = 'ProjectIcon';
        cls += className ? ' ' + className : '';

        return cls;
    }, [className]);
    return (
        <span className={classes}>
          <svg dangerouslySetInnerHTML={{ __html: useTag }} />
        </span>
    );
};

export default Icon;
