import React from 'react';
import PropTypes from 'prop-types';
import TextButton from "../components/TextButton";
// import AskAboutQuestions from "../components/AskAboutQuestions";
import Title, {TitlePropColor} from "../components/typography/Title";
import Block, {BlockPropTypeConfig} from "./Block";
import BlockSection from "./BlockSection";
import HRCard, {HRCardPropTypes} from "../components/HRCard";

const HRCardsBlock = (
    {
        config = {},
        cardsTitle,
        cards,
        cardsTitleColor = 'first',
        cardsTitlePosition = '',
        cardsGoTo,
        list,
        listTitle,
        listColor = 'first',
        listPosition = 'beforeCards',
        listGoTo,
        talk,
        haveQuestions
    }
    ) => {
    return (
        <Block config={config} className="HRPageBlock">
            <div className="container">
                {cards && (
                    <BlockSection>
                        {cardsTitle && (
                            <Title color={cardsTitleColor} className="Block-Title Block-Title-Center" innerHTML={cardsTitle} />
                        )}

                        <div className="clearNegativeMargin">
                           <div className="HRCardsBlock HRCardsList_layout_row">
                               {cards.map((card, i) => (
                                   <HRCard key={i} {...card} />
                               ))}
                            </div>
                        </div>


                    </BlockSection>
                )}
            </div>
        </Block>
    )
};

export default HRCardsBlock;
