import React from "react";

import '../styles/buttons/staticButton.scss';

import iconProcess from '../images/icons/buttons/Loader.svg';
import iconDone from '../images/icons/buttons/Done.svg';

const StaticButton = ({ children, click = () => {}, goto = '', className = '', type = 'text', status = 'default', disabled = false }) => {
    const classes = React.useMemo(() => {
        let cls = 'StaticButton';

        return cls;
    }, [className, status]);

    return (
        <div className="StaticButtonBlock">
        <a href={goto} type={type} className={classes}>
            <span className="Button-Text">{children}</span>
            <span className="Button-Loader">
                <img className='loading' src={iconProcess} alt=""/>
            </span>
            <span className="Button-Done">
                <img src={iconDone} alt=""/>
            </span>
        </a>
        </div>
    )
};

export default StaticButton;
