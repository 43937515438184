import iconCheck from "../../images/icons/styled/first/CheckInStar.svg";
import calendar from "../../images/icons/styled/first/Calendar.svg";
import Hat from "../../images/icons/styled/first/Hat.svg";
import ShieldHR from "../../images/icons/styled/first/ShieldHR.svg";

export default {
    "seo": {
        "title": "Банк 131: вакансии",
        "description": "Работа в Банке 131. Банк 131 — это финтех с банковской лицензией. Мы создаем сложные финансовые продукты и удобные платежные решения для крупных компаний. Наши клиенты – это маркетплейсы, сервисы и международные компании, которые хотят работать с российским рынком"
    },
    "hrBlock": {
        "title": "Работа в Банке 131",
        "description": "Мы создаём решения, которые превосходят ожидания",
        "subdescription": "Мы очень быстро растём, а среди наших клиентов — невероятные компании и проекты, в которые мы верим, которые поддерживаем, и для которых создаём кастомные решения, помогающие быть эффективнее."
    },

    "VacanciesTitle": "Посмотреть вакансии",
    "VacanciesLink": "https://hh.ru/employer/5725304",

    "bottomCaptionTitle": "Ждем ваше резюме на",
    "bottomCaptionMail": "job@131.ru",

    "HRCardsBlock": {
        "cardsTitle": "Как мы работаем",
        "cards": [
            {
                "title": "Марафон, а не спринт",
                "text": "Одинаково фокусируемся на результате и процессе работы",
                "icon": iconCheck,
                "description": "",
                "theme": "cardSecond"
            },
            {
                "title": "Гибкость",
                "text": "Мы готовы к возможным изменениям и приветствуем новые мысли и видение",
                "icon": iconCheck,
                "description": "",
                "theme": "cardSecond"
            },
            {
                "title": "Быстро и прямолинейно",
                "text": "Мы всё ещё стартап и остаёмся верны принципам открытого обсуждения, уверенных решений и проактивных действий",
                "icon": iconCheck,
                "description": "",
                "theme": "cardSecond"
            }
        ]
    },

    "HRCardsBlockSecond": {
            "cardsTitle": "Наши преимущества",
            "cards": [
                {
                    "title": "Гибкий график",
                    "text": "Чтобы оставаться <br />продуктивными",
                    "icon": calendar,
                    "description": "",
                    "theme": "cardFirst"
                },
                {
                    "title": "ДМС",
                    "text": "Чтобы  хорошо <br />себя чувствовать",
                    "icon": ShieldHR,
                    "description": "",
                    "theme": "cardFirst"
                },
                {
                    "title": "Внутренние митапы и лекции",
                    "text": "Чтобы развиваться <br />и расширять кругозор",
                    "icon": Hat,
                    "description": "",
                    "theme": "cardFirst"
                }
            ]
        }
}