import React from "react";
import Layout from "../layout/Layout";
import HRBlock from "../blocks/static/HRBlock";
import SEO from "../components/seo";
import HRCardsBlock from "../blocks/HRCardsBlock";
import TextButton from "../components/TextButton";
import Block, {BlockPropTypeConfig} from "../blocks/Block";

import enMessages from '../i18n/hr/en.js'
import ruMessages from '../i18n/hr/ru.js'

const messages = {
    en: enMessages,
    ru: ruMessages
}

const HRPage = ({ pathContext: { locale } }) => {
    return (
        <Layout locale={locale}>
            <SEO
                title={messages[locale].seo.title}
                description={messages[locale].seo.description}
            />
            <HRBlock locale={locale}/>

            <HRCardsBlock
                cardsTitle={messages[locale].HRCardsBlock.cardsTitle}
                cards={messages[locale].HRCardsBlock.cards}
                cardsTitleColor='second'
                cardsTitlePosition='center'
            />

            <HRCardsBlock
                cardsTitle={messages[locale].HRCardsBlockSecond.cardsTitle}
                cards={messages[locale].HRCardsBlockSecond.cards}
                cardsTitleColor='first'
                cardsTitlePosition='center'
            />

            <Block className="BottomHRBlock">
                <div className="container">
                    <TextButton href={messages[locale].VacanciesLink} iconId="ArrowRight" styleTheme="goTo">{messages[locale].VacanciesTitle}</TextButton>
                </div>
                <div className="container containerHRFeedback">
                    {messages[locale].bottomCaptionTitle} <a href="mailto:job@131.ru" target="_blank" rel="noreferrer">{messages[locale].bottomCaptionMail}</a>
                </div>
            </Block>
        </Layout>
    )
};

export default HRPage;
