import React from 'react';
import Block from "../Block";
import BlockSection from "../BlockSection";
import Title from "../../components/typography/Title";
import StaticButton from "../../components/StaticButton";

import enMessages from '../../i18n/hr/en.js'
import ruMessages from '../../i18n/hr/ru.js'

const messages = {
    en: enMessages,
    ru: ruMessages
}

const HRBlock = ({locale}) => {
    return (
        <Block className="Block_theme_about" config={{
            baseBorder: true,
            animation: '3'
        }}>
            <div className="container">
                <BlockSection>
                    <Title className="Block-Title" level="h1" headerStyleNumb="4">{messages[locale].hrBlock.title}</Title>
                    <div className="Block-Text text3" dangerouslySetInnerHTML={{ __html: messages[locale].hrBlock.description }}/>
                    <div className="Block-Text text4" dangerouslySetInnerHTML={{ __html: messages[locale].hrBlock.subdescription }}/>
                    <StaticButton goto={messages[locale].VacanciesLink}>{messages[locale].VacanciesTitle}</StaticButton>
                </BlockSection>
            </div>
        </Block>

    );
};

export default HRBlock;
